<template>
  <div class="content">
    <div v-if="check">
      <van-cell title="门店图片" is-link center>
        <template #right-icon>
          <van-uploader :before-read="afterRead">
            <img
              v-if="editFrom.logo"
              class="logo"
              :src="editFrom.logo"
              alt="上传图片"
            />
            <p v-else>点击上传</p>
          </van-uploader>
        </template>
      </van-cell>

      <van-cell
        title="门店名称"
        is-link
        :value="editFrom.title"
        @click="edit('门店名称', 'title')"
      />
      <van-cell
        title="门店描述"
        is-link
        :value="editFrom.desc"
        @click="edit('门店描述', 'desc')"
      />
      <van-cell
        title="门店地区"
        is-link
        :value="address"
        @click="edit('门店地区', 'address1')"
      />
      <van-cell
        title="门店详细地址"
        is-link
        :value="editFrom.address"
        @click="edit('门店详细地址', 'address')"
      />
      <van-cell
        title="联系人"
        is-link
        :value="editFrom.name"
        @click="edit('联系人', 'name')"
      />
      <van-cell
        title="联系人手机号"
        is-link
        :value="editFrom.phone"
        @click="edit('联系人手机号', 'phone')"
      />
      <van-cell
        title="登录密码"
        is-link
        :value="editFrom.password"
        @click="edit('密码', 'password')"
      />
      <van-cell
        title="代理商推广码"
        :value="agent.phone"
        @click="edit('代理商推广码', 'agent.phone')"
      />
      <van-cell
        title="代理商姓名"
        :value="agent.name"
        @click="edit('代理商姓名', 'agent.name')"
      />
      <van-popup
        v-model:show="show"
        position="bottom"
        :style="{ height: '30%' }"
        :close-on-click-overlay="false"
      >
        <div class="from">
          <h1>{{ title }}</h1>
          <textarea rows="8" v-model="editFrom[type]"></textarea>
          <div class="btn">
            <button @click="this.show = false">取消</button
            ><button @click="this.show = false">确定</button>
          </div>
        </div>
      </van-popup>
      <Address v-if="type === 'address1'" @confirm="confirmAddress" />
      <van-button
        style="margin-top: 30px"
        type="primary"
        block
        round
        @click="confirm"
        >提交</van-button
      >
    </div>
  </div>
</template>
<script>
import { Row, Popup, Icon, Cell, Button, CellGroup, Uploader } from 'vant'
import Address from '../../components/Address/index.vue'
export default {
  name: 'register',
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Button.name]: Button,
    [CellGroup.name]: CellGroup,
    [Uploader.name]: Uploader,
    Address,
  },
  data() {
    return {
      address: '',
      show: false,
      agent: {},
      editFrom: {},
      type: '',
      check: false,
    }
  },
  created() {
    console.log(this.$route.query.id)
    if (this.$route.query.id) {
      const id = this.$route.query.id
      this.$http('/merchant/getAgent?agent_id=' + id).then((res) => {
        this.check = true
        this.agent = res.data
        this.editFrom.agent_id = res.data.id
      })
    } else {
      this.$notify('非法参数!')
    }
  },
  methods: {
    afterRead(file) {
      this.$http({
        url: '/merchant/sysConfigImageUpload',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: { picture: file },
      }).then((res) => {
        this.editFrom.logo = res.data.src_url
        // this.confirm()
      })
    },
    confirmAddress(res) {
      if (res && res !== null && res.length === 3) {
        this.editFrom.province = res[0].code
        this.editFrom.city = res[1].code
        this.editFrom.county = res[2].code
        this.address = res[0].name + '-' + res[1].name + '-' + res[2].name
      }
      this.type = ''
    },
    edit(name, type) {
      if (type === 'agent.name' || type === 'agent.phone') {
        return
      }
      this.type = type
      if (type !== 'address1') {
        this.show = true
        this.title = '请输入' + name
      }
    },
    confirm() {
      this.$http({
        url: '/merchant/register',
        method: 'post',
        data: this.$http.adornData(this.editFrom),
      }).then(() => {
        this.show = false
        this.$notify({ type: 'success', message: '注册成功' })
        this.$router.push('login')
      })
    },
  },
}
</script>
<style lang="less" scoped>
.logo {
  width: 50px;
  height: 50px;
}
.content {
  height: 100vh;
  width: 100vw;
  background: #ffffff;
  position: absolute;
  z-index: 99999;
}
.from {
  text-align: center;
  overflow: hidden;
  height: 100%;
  h1 {
    margin: 0 auto;
    padding: 10px 0 15px 0;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 35px;
  }
  textarea {
    width: 80%;
    height: 70px;
    background: #f3f3f3;
    border-radius: 8px 8px 8px 8px;
    border: none;
  }
  .btn {
    padding: 15px 0;
  }
  button {
    border: none;
    width: 38%;
    height: 40px;
    // margin-top: 32px;
  }
  button:nth-child(1) {
    color: rgba(0, 0, 0, 0.4);
    // width: 151px;
    // height: 44px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding-left: 10px;
    margin-right: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.4);
  }
  button:nth-child(2) {
    // width: 152px;
    // height: 44px;
    background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
    border-radius: 8px 8px 8px 8px;
    color: #ffffff;
    opacity: 1;
  }
}
</style>
